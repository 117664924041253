import axios from "axios";
import Axios from "../shared/axios";

interface AuthResponse {
  access_token: string;
  name: string;
  role: string;
  photoProfile?: string;
  expires_in: number;
  plan?: {
    id: number;
    name: string;
    status: string;
    expiresAt?: string;
  };
}

export interface AuthServiceResponse {
  data: AuthResponse;
}

class AuthService {
  private tokenRefreshTimeout: NodeJS.Timeout | null = null;
  private readonly TOKEN_REFRESH_THRESHOLD = 300; // Renovar token 5 minutos antes de expirar

  constructor() {
    // Inicializar o refresh token quando o serviço é criado
    this.setupTokenRefresh();
  }

  // Configurar renovação automática do token
  private setupTokenRefresh() {
    const token = localStorage.getItem("TOKEN");
    if (token) {
      try {
        // Decodificar o token JWT
        const payload = JSON.parse(atob(token.split(".")[1]));
        const expiresIn = payload.exp * 1000 - Date.now(); // Converter para milissegundos
        
        // Se o token ainda é válido, agendar renovação
        if (expiresIn > 0) {
          this.scheduleTokenRefresh(expiresIn - this.TOKEN_REFRESH_THRESHOLD * 1000);
        } else {
          // Se o token já expirou, limpar dados e redirecionar para login
          this.logout();
        }
      } catch (error) {
        console.error("Erro ao decodificar token:", error);
        this.logout();
      }
    }
  }

  // Agendar renovação do token
  private scheduleTokenRefresh(timeUntilRefresh: number) {
    if (this.tokenRefreshTimeout) {
      clearTimeout(this.tokenRefreshTimeout);
    }

    this.tokenRefreshTimeout = setTimeout(async () => {
      try {
        await this.refreshToken();
      } catch (error) {
        console.error("Erro ao renovar token:", error);
        this.logout();
      }
    }, timeUntilRefresh);
  }

  // Login com email e senha
  async login(email: string, password: string): Promise<AuthServiceResponse> {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const response = await axios.post<AuthResponse>(`${API_URL}/users/login`, {
        username: email, // Backend espera username
        password
      });
      
      this.handleAuthResponse(response.data);
      return { data: response.data };
    } catch (error) {
      console.error("Erro no login:", error);
      throw error;
    }
  }

  // Login com token existente
  async loginWithToken(token: string): Promise<AuthServiceResponse> {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const response = await axios.post<AuthResponse>(`${API_URL}/users/login-token`, {
        token
      });
      
      this.handleAuthResponse(response.data);
      return { data: response.data };
    } catch (error) {
      console.error("Erro no login com token:", error);
      throw error;
    }
  }

  // Renovar token
  async refreshToken(): Promise<AuthServiceResponse> {
    const API_URL = process.env.REACT_APP_API_URL;
    const oldToken = localStorage.getItem("TOKEN");
    
    if (!oldToken) {
      throw new Error("Nenhum token encontrado");
    }

    try {
      const response = await axios.put<AuthResponse>(`${API_URL}/token/refresh`, {
        oldToken
      });
      
      this.handleAuthResponse(response.data);
      return { data: response.data };
    } catch (error) {
      console.error("Erro ao renovar token:", error);
      throw error;
    }
  }

  // Processar resposta de autenticação
  private handleAuthResponse(data: AuthResponse) {
    localStorage.setItem("TOKEN", data.access_token);
    localStorage.setItem("USER", JSON.stringify({
      name: data.name,
      role: data.role,
      photoProfile: data.photoProfile
    }));

    // Configurar próxima renovação do token
    if (data.expires_in) {
      this.scheduleTokenRefresh((data.expires_in - this.TOKEN_REFRESH_THRESHOLD) * 1000);
    }
  }

  // Verificar se usuário está autenticado
  isAuthenticated(): boolean {
    const token = localStorage.getItem("TOKEN");
    if (!token) return false;

    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      return payload.exp * 1000 > Date.now();
    } catch {
      return false;
    }
  }

  // Logout
  logout() {
    if (this.tokenRefreshTimeout) {
      clearTimeout(this.tokenRefreshTimeout);
    }
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("USER");
    localStorage.removeItem("role");
    window.location.href = "/";
  }

  // Obter token atual
  getToken(): string | null {
    return localStorage.getItem("TOKEN");
  }

  // Obter dados do usuário
  getUserData() {
    const userData = localStorage.getItem("USER");
    return userData ? JSON.parse(userData) : null;
  }
}

const authService = new AuthService();
export default authService;
