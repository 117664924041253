import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import lessonService from "../../services/LessonService";
import CourseContext from "../../context/CourseContext";
import { QuizQuestionsAlternativeCorrectType } from "../../enums/quiz-questions-alternative.enum";
import { QuizQuestionsLevelType } from "../../enums/quiz-questions-level.enum";
import { QuizQuestionsChoicesType } from "../../enums/quiz-questions-choices.enum";
import quizQuestionService from "../../services/QuizQuestionService";
import quizQuestionAnswersService from "../../services/QuizQuestionsAnswersService";
import "./modalQuizQuestion.scss";
import { green } from "@material-ui/core/colors";

interface IQuizQuestion {
  id: number;
  imageName: string;
  question: string;
  alternative1: string;
  alternativeCorrect1: QuizQuestionsAlternativeCorrectType;
  alternative2: string;
  alternativeCorrect2: QuizQuestionsAlternativeCorrectType;
  alternative3: string;
  alternativeCorrect3: QuizQuestionsAlternativeCorrectType;
  alternative4: string;
  alternativeCorrect4: QuizQuestionsAlternativeCorrectType;
  difficultyLevel: QuizQuestionsLevelType;
  type: QuizQuestionsChoicesType;
  file: string;
}

export default function ModalQuizQuestion({
  show,
  onHide,
}: {
  show: boolean;
  onHide: () => void;
}) {
  const [questions, setQuestions] = useState<IQuizQuestion[]>();
  const [question, setQuestion] = useState<IQuizQuestion>();
  const [questionNumber, setQuestionNumber] = useState<number>(0);

  const [answersCount, setAnswersCount] = useState<{
    question: number;
    correct: number;
    skills: { name: string; value: number }[];
  }>();

  const [count, setCount] = useState<{
    perguntas: string;
    respondidas: string;
  }>();

  const { getLesson } = useContext(CourseContext);

  const [isLoading, setLoading] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const selectAnswer = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedOptions.includes(event.target.value)) {
      const values: string[] = selectedOptions;
      let newValues = values.filter((item) => item != event.target.value);
      setSelectedOptions([...newValues]);
    } else {
      setSelectedOptions([...selectedOptions, event.target.value]);
    }
  };

  const LoadQuestion = () => {
    const lesson = getLesson();
    setLoading(true);
    if (lesson) {
      return quizQuestionService
        .getOne(lesson.id)
        .then(async (response) => {
          let quizQuestionsResponse = response.data;
          setQuestion(quizQuestionsResponse);

          const count = await quizQuestionAnswersService.getCountAnswers(
            lesson.id
          );
          setAnswersCount(count.data);

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      setLoading(false);
    }
  };

  const clearModal = () => {
    setSelectedOptions([]);
    setAnswersCount(undefined);
    onHide();
  };

  const testAgain = async () => {
    const lesson = getLesson();
    if (!lesson) return;
    
    try {
      await lessonService.patchIncrementAttempts(lesson.id);
      setSelectedOptions([]); // Limpa as seleções anteriores
      await LoadQuestion();
    } catch (error) {
      console.error("[Quiz] Erro ao tentar refazer o teste:", error);
      // Se houver erro 401, o interceptor do Axios vai tentar renovar o token
      // e fazer a requisição novamente automaticamente
    }
  };

  const saveQuestion = () => {
    if (selectedOptions.length < 1) {
      setQuestion(undefined);
      LoadQuestion();
      return;
    }
    if (question) {
      const data = {
        resposta: selectedOptions,
        id_quizQuestion: question.id,
      };
      return quizQuestionAnswersService.create(data).then(() => {
        setSelectedOptions([]);
        setQuestion(undefined);
        LoadQuestion();
      });
    }
  };

  useEffect(() => {
    setQuestion(undefined);
    LoadQuestion();
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={clearModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ marginRight: "50%" }}
    >
      {question && (
        <>
          <Modal.Header
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="title">Atividades da aula</div>
          </Modal.Header>
          <Modal.Body>
            {question.file !== "N/A" ? (
              <img src={`data:image/png;base64,${question.file}`} />
            ) : (
              ""
            )}
            <div className="modal-pergunta">
              <div
                className="p_normal"
                dangerouslySetInnerHTML={{ __html: question.question }}
              />
            </div>
            <div>
              

              <FormGroup>
                {question.type === "MULTIPLA_ESCOLHA" ? (
                  <>
                    {/* Render Checkbox for multiple choice questions */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedOptions.includes("A")}
                          onChange={selectAnswer}
                          value="A"
                          name={question.alternative1}
                        />
                      }
                      label={question.alternative1}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedOptions.includes("B")}
                          onChange={selectAnswer}
                          value="B"
                          name={question.alternative2}
                        />
                      }
                      label={question.alternative2}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedOptions.includes("C")}
                          onChange={selectAnswer}
                          value="C"
                          name={question.alternative3}
                        />
                      }
                      label={question.alternative3}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedOptions.includes("D")}
                          onChange={selectAnswer}
                          value="D"
                          name={question.alternative4}
                        />
                      }
                      label={question.alternative4}
                    />
                  </>
                ) : (
                  <>
                    {/* Render Radio for single choice questions */}
                    <FormControl component="fieldset">
                      <RadioGroup
                        value={selectedOptions[0]}
                        onChange={selectAnswer}
                      >
                        <FormControlLabel
                          value="A"
                          control={<Radio />}
                          label={question.alternative1}
                        />
                        <FormControlLabel
                          value="B"
                          control={<Radio />}
                          label={question.alternative2}
                        />
                        <FormControlLabel
                          value="C"
                          control={<Radio />}
                          label={question.alternative3}
                        />
                        <FormControlLabel
                          value="D"
                          control={<Radio />}
                          label={question.alternative4}
                        />
                      </RadioGroup>
                    </FormControl>
                  </>
                )}
              </FormGroup>
            </div>
          </Modal.Body>

          <Modal.Footer>
            {/* <Button
              style={{ width: "15%", backgroundColor: "#343434" }}
              onClick={clearModal}
            >
              Fechar
            </Button> */}
            <button
              className="btn_secondary"
              style={{ marginRight: "80%" }}
              onClick={saveQuestion}
            >
              Responder
            </button>
          </Modal.Footer>
        </>
      )}

      {!question && !answersCount && !isLoading && (
        <Modal.Body>
          <Modal.Header closeButton></Modal.Header>

          <p style={{ textAlign: "center" }}>
            <div className="logo">
              <img src="../coin.png" alt="fatecoins" />
            </div>
            {"Ainda não há perguntas para essa aula."}
          </p>

          {count && (
            <p style={{ textAlign: "right" }}>
              {" "}
              {`${count.respondidas ? count.respondidas : "0"} / ${
                count.perguntas ? count.perguntas : "0"
              } Questões respondidas`}
            </p>
          )}
        </Modal.Body>
      )}

      {!question && !!answersCount && !isLoading && (
        <Modal.Body>
          <Modal.Header closeButton></Modal.Header>
          <p style={{ textAlign: "center" }}>
            <div
              className={
                Math.floor(
                  (answersCount.correct / answersCount.question) * 100
                ) >= 80
                  ? "porcentagem-positiva"
                  : "porcentagem-negativa"
              }
            >
              {answersCount.question > 0
                ? Math.floor(
                    (answersCount.correct / answersCount.question) * 100
                  )
                : 0}
              %
            </div>
            {`Você acertou ${answersCount.correct} de ${answersCount.question} perguntas.`}
          </p>

          {/* Só mostra o quanto melhorou das skills se acertar 100% */}
          {answersCount.correct == answersCount.question &&
            answersCount.question > 0 && (
              <>
                <p className="bold">Você melhorou suas skills:</p>
                {answersCount.skills &&
                  answersCount.skills.map((s) => (
                    <p className="skill-item">
                      <strong>+{s.value}</strong> {s.name}
                    </p>
                  ))}
              </>
            )}

          {answersCount.correct != answersCount.question &&
            answersCount.question > 0 && (
              <>
                <p className="skill-item">
                  Para melhorar suas skills, você precisa alcançar 100%
                </p>
                <Button style={{ width: "35%" }} onClick={testAgain}>
                  Refazer o teste
                </Button>
              </>
            )}
        </Modal.Body>
      )}
    </Modal>
  );
}
