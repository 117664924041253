import { id } from "date-fns/locale";
import ICreateComment from "../interfaces/ICreateComment";
import ICreateQuestion from "../interfaces/ICreateQuestion";
import Axios from "../shared/axios";

interface ILogEvent {
  id_lesson: number | undefined;
  event_time: number;
  type: string;
}

interface ICompleteLesson {
  id_lesson: number | undefined;
}

class LessonService {
  async getAll(idCourse: number): Promise<any> {
    return Axios.get(`/lessons/${idCourse}/all`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async getDataByCourseStudent(idCourse: number): Promise<any> {
    let token = localStorage.getItem("TOKEN");
    console.log(idCourse, token);
    const headers = {Authorization: "Bearer " + token}
    return Axios.get(`/lessons/get-data-by-course-student/${idCourse}`, {headers})
       
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async start(data: any) {
    let token = localStorage.getItem("TOKEN");
    return Axios.post("/students-lessons/create", data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async logEvent(data: ILogEvent) {
    let token = localStorage.getItem("TOKEN");
    return Axios.post("/log-students-lessons/log-event", data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async completeLesson(data: ICompleteLesson) {
    let token = localStorage.getItem("TOKEN");
    return Axios.put(`/students-lessons/complete/${data.id_lesson}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async question(data: ICreateQuestion) {
    let token = localStorage.getItem("TOKEN");
    return Axios.post("/questions/create", data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getAllQuestions(idLesson: number): Promise<any> {
    let token = localStorage.getItem("TOKEN");
    return Axios.get(`/questions/find-all/${idLesson}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async comment(data: ICreateComment) {
    let token = localStorage.getItem("TOKEN");
    return Axios.post("/comments/create", data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getAllComments(idLesson: number): Promise<any> {
    return Axios.get(`/comments/find-all/${idLesson}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async getLastLesson(idCourse: number): Promise<any> {
    return Axios.get(`/log-students-lessons/course/${idCourse}/last-lesson`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async getNextLesson(idLesson: number | undefined): Promise<any> {
    return Axios.get(`/lessons/find/${idLesson}/NEXT`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async getPreviousLesson(idLesson: number | undefined): Promise<any> {
    return Axios.get(`/lessons/find/${idLesson}/PREV`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async getQuestion(idLesson: number): Promise<any> {
    return Axios.get(`/questionAnswers/find?lesson=${idLesson}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async saveAnswer(data: any): Promise<any> {
    return Axios.post(`/questionAnswers/create-answer`, data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async countAnswer(lessonId: number): Promise<any> {
    return Axios.get(`/questionAnswers/count-answers?lesson=${lessonId}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async getCoursesComplete(coursesId: number): Promise<any> {
    return Axios.get(`/lessons/course-complete/${coursesId}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }
  async getCountLesson(): Promise<any> {
    return Axios.get(`lessons/all-lessons`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async patchIncrementAttempts(lessonId: number): Promise<any> {
    try {
      const result = await Axios.patch(`students-lessons/attempts/${lessonId}`);
      return result;
    } catch (error: any) {
      if (error.response?.status === 401) {
        // Deixa o interceptor do Axios lidar com a renovação do token
        throw error;
      }
      console.error("[Quiz] Erro ao incrementar tentativas:", error);
      throw error;
    }
  }
  async getAllByStudentAndCourse(courseId: number): Promise<any> {
    return Axios.get(`students-lessons/progress/${courseId}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }


  async getAllCourses(): Promise<any> {
    return Axios.get('/courses/all') // Supondo que este seja o endpoint para obter todos os cursos
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

const lessonService = new LessonService();
export default lessonService;
