import axios from "axios";

let API_URL = process.env.REACT_APP_API_URL;

// Flag para controlar se já está renovando o token
let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

// Axios Config
const Axios = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000 // 30 segundos de timeout
});

Axios.interceptors.request.use(async (config) => {
  let token = await localStorage.getItem("TOKEN");
  console.log("Token atual:", token); // Debug

  if (token && config && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    const originalReq = err.config;
    
    if (err.response?.status === 401 && 
        originalReq && 
        !originalReq._retry && 
        !originalReq.url.endsWith("login")) {
      
      if (isRefreshing) {
        // Se já está renovando, adiciona à fila
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalReq.headers["Authorization"] = `Bearer ${token}`;
            return Axios(originalReq);
          })
          .catch(err => Promise.reject(err));
      }

      originalReq._retry = true;
      isRefreshing = true;

      try {
        let oldToken = await localStorage.getItem("TOKEN");
        const res = await axios.put(`${API_URL}/token/refresh`, { oldToken });
        
        if (res.data.access_token) {
          const newToken = res.data.access_token;
          localStorage.setItem("TOKEN", newToken);
          originalReq.headers["Authorization"] = `Bearer ${newToken}`;
          
          // Processa a fila de requisições que falharam
          processQueue(null, newToken);
          
          return Axios(originalReq);
        }
      } catch (refreshError) {
        processQueue(refreshError, null);
        // Se não conseguir renovar o token, redireciona para login
        window.location.href = "/login";
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }
    return Promise.reject(err);
  }
);

export default Axios;
