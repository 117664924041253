import React, { createContext, useState, useEffect } from "react";
import authService from "../services/AuthService";

interface AuthContextData {
  signed: boolean;
  loading: boolean;
  markAsSigned(): void;
  markAsNotSigned(): void;
  role: string | null;
  storeRole(permission: string): void;
  getRole(): string | null;
  name: string | null;
  storeName(username: string): void;
  getName(): string | null;
  getPhotoProfile(): string | null;
  storePhotoProfile(photo: string): void;
  reloadLocalStorage(): void;
}

interface ProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<ProviderProps> = ({ children }) => {
  const [signed, setSigned] = useState(false);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [profileImage, setProfileImage] = useState<string | null>(null);

  useEffect(() => {
    // Verificar autenticação ao montar o componente
    const checkAuth = async () => {
      try {
        if (authService.isAuthenticated()) {
          const userData = authService.getUserData();
          if (userData) {
            setName(userData.name);
            setRole(userData.role);
            setProfileImage(userData.photoProfile);
            setSigned(true);
          }
        }
      } catch (error) {
        console.error("Erro ao verificar autenticação:", error);
        markAsNotSigned();
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  function reloadLocalStorage() {
    const userData = authService.getUserData();
    if (userData) {
      setName(userData.name);
      setRole(userData.role);
      setProfileImage(userData.photoProfile);
    }
  }

  function markAsSigned() {
    setSigned(true);
    reloadLocalStorage();
  }

  function markAsNotSigned() {
    authService.logout();
    setSigned(false);
    setName(null);
    setProfileImage(null);
    setRole(null);
  }

  function storeRole(permission: string) {
    setRole(permission);
    const userData = authService.getUserData();
    if (userData) {
      localStorage.setItem("USER", JSON.stringify({
        ...userData,
        role: permission
      }));
    }
  }

  function getRole(): string | null {
    if (role) return role;
    const userData = authService.getUserData();
    if (userData) {
      setRole(userData.role);
      return userData.role;
    }
    return null;
  }

  function storeName(username: string) {
    setName(username);
    const userData = authService.getUserData();
    if (userData) {
      localStorage.setItem("USER", JSON.stringify({
        ...userData,
        name: username
      }));
    }
  }

  function getName(): string | null {
    if (name) return name;
    const userData = authService.getUserData();
    if (userData) {
      setName(userData.name);
      return userData.name;
    }
    return null;
  }

  function getPhotoProfile(): string | null {
    if (profileImage) return profileImage;
    const userData = authService.getUserData();
    if (userData) {
      setProfileImage(userData.photoProfile);
      return userData.photoProfile;
    }
    return null;
  }

  function storePhotoProfile(photo: string) {
    setProfileImage(photo);
    const userData = authService.getUserData();
    if (userData) {
      localStorage.setItem("USER", JSON.stringify({
        ...userData,
        photoProfile: photo
      }));
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signed,
        loading,
        markAsSigned,
        markAsNotSigned,
        role,
        storeRole,
        getRole,
        name,
        storeName,
        getName,
        getPhotoProfile,
        storePhotoProfile,
        reloadLocalStorage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
