import { ResultDto } from "../interfaces/IResult";
import IValuesForm from "../interfaces/IValuesForm";
import axios from "axios";

class PasswordResetService {
  async create(data: any) {
    const API_URL = process.env.REACT_APP_API_URL;
    console.log("[Recuperação de senha] Iniciando requisição:", {
      url: `${API_URL}/password-resets/send-reset-password-email`,
      data
    });

    try {
      const response = await axios.post(
        `${API_URL}/password-resets/send-reset-password-email`, 
        data,
        {
          timeout: 10000,
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log("[Recuperação de senha] Resposta:", response);
      return response;
    } catch (error: any) {
      if (error.code === 'ECONNABORTED') {
        console.error("[Recuperação de senha] Erro: Timeout - A API demorou muito para responder");
        throw new Error("A API demorou muito para responder. Por favor, tente novamente em alguns minutos.");
      }

      if (!error.response) {
        console.error("[Recuperação de senha] Erro: Sem resposta da API - Possível que a API esteja fora do ar");
        throw new Error("Não foi possível conectar ao servidor. Por favor, tente novamente em alguns minutos.");
      }

      console.error("[Recuperação de senha] Erro detalhado:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        headers: error.response?.headers
      });
      throw error;
    }
  }

  async updatePassword(data: IValuesForm): Promise<ResultDto> {
    const API_URL = process.env.REACT_APP_API_URL;
    console.log("[Alteração de senha] Iniciando requisição:", {
      url: `${API_URL}/password-resets/change-password`,
      data
    });

    try {
      const response = await axios.post(
        `${API_URL}/password-resets/change-password`, 
        data,
        {
          timeout: 10000,
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log("[Alteração de senha] Resposta:", response);
      return {
        status: true,
        message: "Senha alterada com sucesso!"
      } as ResultDto;
    } catch (error: any) {
      if (error.code === 'ECONNABORTED') {
        console.error("[Alteração de senha] Erro: Timeout - A API demorou muito para responder");
        return {
          status: false,
          message: "A API demorou muito para responder. Por favor, tente novamente em alguns minutos."
        } as ResultDto;
      }

      if (!error.response) {
        console.error("[Alteração de senha] Erro: Sem resposta da API - Possível que a API esteja fora do ar");
        return {
          status: false,
          message: "Não foi possível conectar ao servidor. Por favor, tente novamente em alguns minutos."
        } as ResultDto;
      }

      console.error("[Alteração de senha] Erro detalhado:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        headers: error.response?.headers
      });
      return {
        status: false,
        message: "Erro ao alterar a senha: " + error.message
      } as ResultDto;
    }
  }
}

const passwordResetService = new PasswordResetService();
export default passwordResetService;
