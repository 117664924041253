import axios from "axios";
import Axios from "../shared/axios";
import { UpdateUserDto } from "../interfaces/IUser";
import authService from "./AuthService";

interface LoginData {
  email: string;
  password: string;
}

class UsersService {
  async create(data: any) {
    return Axios.post("/students/create", data)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async login(data: LoginData) {
    try {
      const response = await authService.login(data.email, data.password);
      localStorage.setItem("TOKEN", response.data.access_token);
      localStorage.setItem("USER", JSON.stringify({
        name: response.data.name,
        role: response.data.role,
        photoProfile: response.data.photoProfile
      }));
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async loginComToken(data: { token: string }) {
    try {
      const response = await authService.loginWithToken(data.token);
      localStorage.setItem("TOKEN", response.data.access_token);
      localStorage.setItem("USER", JSON.stringify({
        name: response.data.name,
        role: response.data.role,
        photoProfile: response.data.photoProfile
      }));
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getByToken() {
    const token = authService.getToken();
    if (!token) {
      return Promise.reject(new Error("Token não encontrado"));
    }

    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const response = await axios({
        url: API_URL + "/users/get-by-token",
        method: "GET",
        timeout: 5000,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getByTokenCompany() {
    const token = authService.getToken();
    if (!token) {
      return Promise.reject(new Error("Token não encontrado"));
    }

    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const response = await axios({
        url: API_URL + "/users/get-by-token-company",
        method: "GET",
        timeout: 5000,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updatePassword(data: any) {
    return Axios.put("users/pass/:id", data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async changePassword(currentPassword: string, newPassword: string) {
    const data = {
      currentPassword,
      newPassword
    }
    return Axios.put("users/change-password", data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async updateDataProfile(data: UpdateUserDto): Promise<any> {
    try {
      const token = authService.getToken();
      if (!token) {
        return Promise.reject({
          message: "TOKEN NÃO ENCONTRADO",
        });
      }
  
      const headers = {
        Authorization: `Bearer ${token}`,
      };
  
      const result = await Axios.put("users/update/", data, { headers });
      return result.data;
    } catch (error: any) {
      if (error.response) {
        // Verifica se o erro é devido ao e-mail já estar em uso
        if (error.response.status === 400 && error.response.data.errorMessage.includes("E-mail já sendo utilizado")) {
          return Promise.reject({
            message: "Este e-mail já está em uso. Por favor, escolha outro.",
          });
        }
  
        // Lida com outros erros retornados do servidor
        return Promise.reject(error.response.data);
      } else {
        return Promise.reject({
          message: "Erro ao enviar dados para o servidor",
        });
      }
    }
  }
  
  async updateDataProfileCompany(data: UpdateUserDto): Promise<any> {
    try {
      const token = authService.getToken();
      if (!token) {
        return Promise.reject({
          message: "TOKEN NÃO ENCONTRADO",
        });
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      }


      const result = await Axios.put("users/update-company/", data, { headers });
      return result.data;
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(error.response.data);
      } else {
        return Promise.reject({
          message: "Erro ao enviar dados para o servidor",
        });
      }
    }
  }

  async uploadPhotoProfile(file: File): Promise<any> {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const token = authService.getToken();
      if (!token) {
        return Promise.reject({
          message: "TOKEN NÃO ENCONTRADO",
        });
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      }


      const result = await Axios.put("users/photo-profile/upload/", formData, { headers });
      return result.data;
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(error.response.data);
      } else {
        return Promise.reject({
          message: "Erro ao enviar dados para o servidor",
        });
      }
    }
  }

  async removePhotoProfile(): Promise<any> {
    try {
      const token = authService.getToken();
      if (!token) {
        return Promise.reject({
          message: "TOKEN NÃO ENCONTRADO",
        });
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      }


      const result = await Axios.put("users/photo-profile/delete/", { headers });
      return result.data;
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(error.response.data);
      } else {
        return Promise.reject({
          message: "Erro ao enviar dados para o servidor",
        });
      }
    }
  }


  //Busca nome e imagem do student para o skillsLinkedin
  async getStudentInfo(): Promise<any> {
    try {
      const token = authService.getToken();
      if (!token) {
        return Promise.reject({
          message: "TOKEN NÃO ENCONTRADO",
        });
      }
  
      const headers = {
        Authorization: `Bearer ${token}`,
      };
  
      // Ajuste a URL para incluir o ID do estudante
      const result = await Axios.get(`users/find-one-image`, { headers });
      
      return {
        name: result.data.name,
        profileImage: result.data.profileImage,
      };
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(error.response.data);
      } else {
        return Promise.reject({
          message: "Erro ao buscar dados do servidor",
        });
      }
    }
  }
  
  
}

const usersService = new UsersService();
export default usersService;
